<template>
  <v-app-bar
    app
    flat
    clipped-left
    clipped-right
    color="menu"
    class="overflow-visible elevation-12"
  >
    <v-app-bar-nav-icon @click="toggleDrawer" />

    <v-img
      class="mx-2"
      contain
      max-height="40"
      max-width="40"
      :src="require(`@/assets/logo.png`)"
    />

    <v-col md="4" />

    <v-col class="d-flex justify-end pa-0">
      <v-tooltip bottom>
        <template #activator="{ on, attrs }">
          <v-btn v-bind="attrs" icon v-on="on" @click="toggleThemeDarkMode">
            <v-icon :style="{transform: `rotate(${$vuetify.theme.dark ? 0 : -190 }deg)`}">
              mdi-theme-light-dark
            </v-icon>
          </v-btn>
        </template>
        <span>{{ ($vuetify.theme.dark ? 'Light' : 'Dark') + ' Mode' }}</span>
      </v-tooltip>
    </v-col>

    <span class="align-center justify-center">
      <locale-changer />
    </span>

    <v-col v-if="connected" class="text-right">
      <v-btn @click="doDisconnect">DISCONNECT</v-btn>
      <!--<v-btn v-if="!unlocked" @click="doUnlock">UNLOCK</v-btn>-->
      <v-chip
        v-if="$vuetify.breakpoint.mdAndUp"
        close-icon="mdi-close-outline"
        outlined
        class="align-center"
      >
        {{ totalBalance / 10 ** 18 }} GGT<br>
      </v-chip>
      <v-chip
        v-if="$vuetify.breakpoint.mdAndUp"
        close-icon="mdi-close-outline"
        outlined
        class="align-center"
      >
        {{ stackedBalance }} / {{ lockedBalance }}<br>
      </v-chip>
      <v-menu
        bottom
        min-width="200px"
        rounded
        offset-y
        :close-on-content-click="false"
      >
        <template #activator="{ on }">
          <v-btn
            icon
            x-large
            v-on="on"
          >
            <v-avatar
              size="36"
              color="brown"
            >
              <img
                :src="mainHost + `/avatar?addr=` + address"
                alt="Avatar"
              >
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar color="brown">
                <img
                  :src="mainHost + `/avatar?addr=` + address"
                  alt="Avatar"
                >
              </v-avatar>
              <h3 class="mt-3">{{ profileName }}</h3>
              <v-chip
                v-if="$vuetify.breakpoint.mdAndDown"
                close-icon="mdi-close-outline"
                outlined
                class="align-center"
              >
                {{ totalBalance / 10 ** 18 }} GGT
              </v-chip>
              <v-divider class="my-3" />
              <v-btn
                :to="`/profile/${address}`"
                depressed
                rounded
                text
              >
                Edit Account
              </v-btn>
              <v-divider class="my-3" />
              <div>
                <v-btn
                  depressed
                  rounded
                  text
                  @click="doDisconnect"
                >
                  Disconnect
                </v-btn>
              </div>
            </div></v-list-item-content>
        </v-card>
      </v-menu>
    </v-col>
    <v-col v-else class="text-right">
      <v-btn @click="doConnect">Connect</v-btn>
    </v-col>
  </v-app-bar>
</template>

<script>
  // Utilities
  import {
    mapActions,
    mapState,
    mapMutations,
  } from 'vuex'
  import LocaleChanger from '../LocaleChanger.vue'

  export default {
    name: 'CoreAppBar',
    components: { LocaleChanger },

    computed: {
      cookie () {
        return document.cookie
      },
      ...mapState('platform', ['mainHost']),
      ...mapState('rpc', ['address', 'connected']),
      ...mapState('wallet', ['unlocked', 'web3', 'profileName', 'totalBalance', 'lockedBalance', 'stackedBalance']),
    },
    mounted () {
      this.initPlatform()
      this.initRpc()
    },
    methods: {
      ...mapActions('platform', ['initPlatform']),
      ...mapActions('rpc', ['initRpc']),
      ...mapActions('wallet', ['sendMsg']),
      ...mapMutations('wallet', ['setUnlocked']),
      ...mapMutations('app', ['toggleDrawer']),
      ...mapActions('rpc', ['connect', 'disconnect']),
      async doUnlock () {
        var sign = await this.web3.eth.sign(this.web3.utils.sha3('sign'), this.address)
        this.sendMsg(JSON.stringify({ msg: 'do_unlock', sign }))
        // this.setUnlocked(sign)
        // console.log('do unlock')
      },
      gotoProfile () {

      },
      doConnect () {
        console.log('z', this.$router)
        this.connect(this.$router)
      },
      doDisconnect () {
        this.disconnect(this.$router)
      },
      toggleThemeDarkMode () {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark
        localStorage.setItem('dark_theme', this.$vuetify.theme.dark.toString())
      },
    },
  }
</script>
